import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/vsts/work/1/s/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Navigation } from "../../src/components/Navigation";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "create-the-sandbox"
    }}>{`Create the Sandbox`}</h1>
    <p><a parentName="p" {...{
        "href": "https://havn.skillcore.no/"
      }}>{`ContainerHavn`}</a>{` is a SaaS (Software as a Service) service we've created, which enables you to easily create your own
personal Business Central instance for development. This way you won't have to worry
about someone else working on the same instance as you. 🚀`}</p>
    <figure>
  <img src="/public/images/container-havn-home.png" title="The container overview on ContainerHavn" />
  <figcaption>
    The container overview allows you to easily manage your container, as well
    as connect to it from Visual Studio Code.
  </figcaption>
    </figure>
    <p>{`To create your development environment, click the blue `}<strong parentName="p">{`Create`}</strong>{` button in the top right part of the page:`}</p>
    <figure>
  <img src="/public/images/container-havn-create.png" title="The create container form on ContainerHavn" />
  <figcaption>
    There's a lot of options for you to choose from here, but all the
    recommended defaults have been set for you automatically.
  </figcaption>
    </figure>
    <p>{`Give it a fitting name, leave the rest of the options to their defaults and hit `}<strong parentName="p">{`Create`}</strong>{`.`}</p>
    <figure>
  <img src="/public/images/container-havn-creating.png" title="The overview showing that the container is creating" />
  <figcaption>The overview shows you a live view of the process.</figcaption>
    </figure>
    <p>{`You can even see the logs if you want to:`}</p>
    <figure>
  <img src="/public/images/container-havn-logs.png" title="The live logs" />
  <figcaption>
    The logs are updated in realtime, so you don't have to refresh the page.
  </figcaption>
    </figure>
    <h2 {...{
      "id": "connect-to-the-container"
    }}>{`Connect to the container`}</h2>
    <p>{`Once it's done, copy the `}<em parentName="p">{`launch.json`}</em>{` configuration by clicking it in the `}<strong parentName="p">{`VS Code`}</strong>{` column:`}</p>
    <figure>
  <img src="/public/images/container-havn-created.png" title="The container when it's created and running" />
  <figcaption>
    Clicking the name takes you to the Business Central login page. The{" "}
    <b>User 1</b> and <b>User 2</b> columns contain the usernames; clicking them
    will copy their respective passwords. Clicking ⏸️ will stop the container,
    releasing the CPU and RAM resources on the VM 🖥️.
  </figcaption>
    </figure>
    <p>{`Paste it into `}<em parentName="p">{`app/.vscode/launch.json`}</em>{`, like this:`}</p>
    <figure>
  <img src="/public/images/vindfang-demo-launch-json.png" title="The launch.json file with the ContainerHavn connection" />
  <figcaption>
    The <em>launch.json</em> file tells Visual Studio Code which Business
    Central instances it should connect to.
  </figcaption>
    </figure>
    <Navigation previous="/my-first-extension/create-app" next="/my-first-extension/publish-app" mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      